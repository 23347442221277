<template>
  <div id="warehouse">
    <nav>
      <!-- <span
        @click="
          form.status = '全部';
          form.page = 1;
          $forceUpdate();
          getTableNum(form);
        "
        :class="form.status == '全部' ? 'active' : ''"
        ><span class="label">{{ $fanyi("所有订单") }}</span
        ><span class="numberCount">{{ tableTypeNum[0].num }}</span></span
      >
      --><span
        @click="
          form.status = '待收货订单';
          form.page = 1;
          $forceUpdate();
          getTableNum();
        "
        :class="form.status == '待收货订单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("待收货") }}</span
        ><span class="numberCount">{{ tableTypeNum[1].num }}</span></span
      >
      <span
        @click="
          form.status = '检品中订单';
          form.page = 1;
          $forceUpdate();
          getTableNum();
        "
        :class="form.status == '检品中订单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("检品中") }}</span
        ><span class="numberCount">{{ tableTypeNum[2].num }}</span></span
      >
      <span
        @click="
          form.status = '在RAKUMART仓库中订单';
          form.page = 1;
          $forceUpdate();
          getTableNum();
        "
        :class="form.status == '在RAKUMART仓库中订单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("在RAKUMART仓库中") }}</span
        ><span class="numberCount">{{ tableTypeNum[3].num }}</span></span
      >
      <span
        @click="
          form.status = '国际运费待报价配送单';
          form.page = 1;
          $forceUpdate();
          getTableNum();
        "
        :class="form.status == '国际运费待报价配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("国际运费待报价") }}</span
        ><span class="numberCount">{{ tableTypeNum[4].num }}</span></span
      >
      <span
        @click="
          form.status = '国际运费待支付配送单';
          form.page = 1;
          $forceUpdate();
          getTableNum();
        "
        :class="form.status == '国际运费待支付配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("国际运费待支付") }}</span
        ><span class="numberCount">{{ tableTypeNum[5].num }}</span></span
      >
    </nav>
    <div>
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getTableNum()"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <button class="searchBtn" @click="getTableNum()">
          {{ $fanyi("搜索") }}
        </button>
        <!--        <button v-show="iscom" class="shippingOrder" @click="addGoods()">-->
        <!--          {{ $fanyi("添加选中商品") }}-->
        <!--        </button>-->
        <!--        <button-->
        <!--          v-show="!iscom && form.status != 'status_unpack'"-->
        <!--          class="shippingOrder"-->
        <!--          @click="toDeliveryDetails()"-->
        <!--        >-->
        <!--          {{ $fanyi("提出配送单") }}-->
        <!--        </button>-->
      </div>
      <!-- 表格 -->
      <div class="dataBody">
        <div class="myTable">
          <ol class="tableHead">
            <li class="flex05">Nº</li>
            <li>{{ $fanyi("照片") }}</li>
            <li class="flex2">{{ $fanyi("描述") }}</li>
            <li>{{ $fanyi("国内运费") }}</li>
            <li>{{ $fanyi("单价") }}</li>
            <li>{{ $fanyi("数量") }}</li>
            <li>{{ $fanyi("小计") }}</li>
            <li>{{ $fanyi("状态") }}</li>
            <li class="flex15" v-if="showCaoZuo">{{ $fanyi("操作") }}</li>
          </ol>

          <div class="tableBody">
            <div
              class="tableBodyOpt"
              v-for="(tableDataItem, tableIndex) in tableData"
              :key="tableIndex"
            >
              <!-- 订单数据行 -->

              <div class="serialNumber">
                <div class="order">
                  <div class="img">
                    <img
                      @click="
                        tableDataItem.showChildData = !tableDataItem.showChildData;
                        $forceUpdate();
                      "
                      v-bind:class="{
                        openIcon: tableDataItem.showChildData == true,
                      }"
                      src="../../../../assets/user-img/jinrujaintouxiao.svg"
                      alt=""
                    />
                  </div>

                  <div class="order_sn">
                    <div>
                      <p>
                        <span>{{ $fanyi("订单号") }}：</span>
                        <button
                          @click="
                            $fun.routerToPage(
                              '/OrderDetails?type=purchase&order_sn=' +
                                tableDataItem.order_sn,
                              true
                            )
                          "
                        >
                          {{ tableDataItem.order_sn }}
                        </button>
                      </p>
                      <p>
                        <span>{{ $fanyi("配送单号") }}：</span>
                        <button
                          @click="
                            $fun.routerToPage(
                              '/deliveryDetails?type=purchase&order_sn=' +
                                tableDataItem.porder_sn
                            )
                          "
                        >
                          {{ tableDataItem.porder_sn }}
                        </button>
                      </p>
                    </div>
                    <div
                      class="guoJiWuLiuFeiYong"
                      v-if="form.status === '国际运费待支付配送单'"
                    >
                      {{ $fanyi("国际运费") }}: <br />
                      <span class="yunfeipri"
                        >€{{
                          $fun.EURNumSegmentation(
                            tableDataItem.porder_all_price
                          )
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="item">
                  {{ tableDataItem.porder_num }}
                </div>
                <div class="item">
                  {{ tableDataItem.totalPrice }}
                </div>
                <div class="item">
                  {{ $fanyi(tableDataItem.statusName) }}
                </div>
                <div class="payBtn item" v-if="showCaoZuo">
                  <span
                    v-if="
                      [
                        '西班牙派送配送单',
                        '国际运费待支付配送单',
                        '购买完成',
                      ].indexOf(form.status) != -1
                    "
                    @click="
                      $fun.routerToPage(
                        '/deliveryDetails?type=purchase&order_sn=' +
                          tableDataItem.porder_sn
                      )
                    "
                  >
                    {{ $fanyi("支付") }}</span
                  >
                </div>
              </div>
              <ul
                v-for="(goodsItem, goodsIndex) in tableDataItem.order_detail"
                :key="goodsIndex"
                v-show="tableDataItem.showChildData"
              >
                <li class="flex05">{{ goodsIndex + 1 }}</li>
                <li>
                  <el-popover placement="right" trigger="hover">
                    <img
                      :src="goodsItem.pic"
                      alt=""
                      style="width: 300px; height: 300px; cursor: pointer"
                    />

                    <el-image
                      :src="goodsItem.pic"
                      @click="$fun.toCommodityDetails(goodsItem.goods_id)"
                      style="width: 60px; height: 60px; cursor: pointer"
                      slot="reference"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </li>
                <li class="flex2 shangpShuxing">
                  <div>
                    <p
                      v-for="(item, index) in goodsItem.detail"
                      :key="index"
                      :title="item.key + ':' + item.value"
                    >
                      <span class="detailKey">{{ item.key }}</span>
                      ：
                      <span class="detailValue">{{ item.value }}</span>
                    </p>
                  </div>
                </li>
                <li>
                  {{ $fun.RMBNumSegmentation(goodsItem.confirm_freight) }}
                </li>
                <li>
                  {{ $fun.RMBNumSegmentation(goodsItem.price) }}
                </li>
                <li>
                  {{ $fun.RMBNumSegmentation(goodsItem.num) }}
                </li>
                <li>
                  {{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(goodsItem.num * goodsItem.price)
                    )
                  }}
                </li>
                <li>- -</li>
                <li class="flex15" v-if="showCaoZuo">- -</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="allpag">
        <a
          href="javascript:;"
          @click="
            form.page = 1;
            getTableNum();
          "
          >{{ $fanyi("回到首页") }}</a
        >
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        status: "检品中订单",
        searchText: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
      actIds: [],
      tableTypeNum: [
        {
          status: "全部",

          num: 0, //数量
        },
        {
          status: "待收货",

          num: 0, //数量
        },
        {
          status: "检品中",

          num: 0, //数量
        },
        {
          status: "在RAKUMART仓库中",

          num: 0, //数量
        },
        {
          status: "国际运费待报价",

          num: 0, //数量
        },
        {
          status: "国际运费待支付",

          num: 0, //数量
        },
      ],
      checked: false, //是否全选
      iscom: false, //是否作为添加商品的弹窗组件显示
    };
  },
  components: {},
  computed: {
    showCaoZuo() {
      return ["全部", "国际运费待支付配送单"].indexOf(this.form.status) != -1;
    },
  },
  created() {
    if (this.$route.query.status) {
      switch (this.$route.query.status) {
        case "ordersToBeReceived":
          this.form.status = "待收货订单";
          break;
        case "orderInRAKUMARTWarehouse":
          this.form.status = "在RAKUMART仓库中订单";
          break;
        case "orderInInspection":
          this.form.status = "检品中订单";
          break;
        case "internationalFreightToBeQuotedDeliverySheet":
          this.form.status = "国际运费待报价配送单";
          break;
        case "internationalFreightToBePaidShippingBill":
          this.form.status = "国际运费待支付配送单";
          break;
        default:
          this.form.status = this.$route.query.status;
          break;
      }
    }
    if (this.$route.name != "warehouse") this.iscom = true;
    if (
      ["国际运费待支付配送单", "国际运费待报价配送单"].indexOf(
        this.form.status
      ) != -1
    ) {
      this.getTableNum();
    } else {
      this.getTableNum();
    }
  },
  methods: {
    // 获取顶部数据的数量统计
    getTableNum() {
      this.$api.warehouseOrderCountNum().then((res) => {
        // this.tableTypeNum=res.data;
        res.data.forEach((item) => {
          switch (item.status) {
            case "待收货订单":
              this.tableTypeNum[1].num = item.num;
              break;
            case "检品中订单":
              this.tableTypeNum[2].num = item.num;
              break;
            case "在RAKUMART仓库中订单":
              this.tableTypeNum[3].num = item.num;
              break;
            case "国际运费待报价配送单":
              this.tableTypeNum[4].num = item.num;
              break;
            case "国际运费待支付配送单":
              this.tableTypeNum[5].num = item.num;
              break;
            case "全部状态":
              this.tableTypeNum[0].num = item.num;
              break;
          }
        });
        this.getWarehouseOrderList();
      });
    },
    //获取RAKUMART仓库处理
    getWarehouseOrderList() {
      if (
        ["国际运费待报价配送单", "国际运费待支付配送单"].indexOf(
          this.form.status
        ) != -1
      ) {
        var type = "prsong";
      }
      if (type == "prsong") {
        this.$api.porderListV2(this.form).then((res) => {
          if (res.code != 0) return false;
          if (res.data.data.length == 0) {
            this.tableData = [];
            return false;
          }
          this.total = res.data.total;
          this.peisongChange(res.data.data);
          this.tableData = res.data.data;
          this.dataProcessing(this.tableData, type);
        });
      } else {
        this.$api.warehouseOrderList(this.form).then((res) => {
          if (res.code != 0) return false;
          if (res.data.data.length == 0) {
            this.tableData = [];
            return false;
          }
          this.total = res.data.total;
          this.tableData = res.data.data;
          //console.log("订单", res.data.data);
          this.dataProcessing(this.tableData);
        });
      }
    },
    peisongChange(data) {
      for (let i in data) {
        data[i].order_id = data[i].porder_detail[0].order_detail.order_id;
        data[i].order_sn = data[i].porder_detail[0].order_sn;
        data[i].order_detail = [
          {
            porder_detail: [
              {
                // porder_sn: data[i].porder_detail.porder_sn,
                porder: {
                  foreign_express_price:
                    data[i].porder_detail[0].order_detail.order
                      .foreign_express_price,
                },
              },
            ],
            confirm_price: data[i].porder_detail[0].order_detail.confirm_price,
            confirm_num: data[i].porder_detail[0].order_detail.confirm_num,
            detail: data[i].porder_detail[0].order_detail.detail,
            pic: data[i].porder_detail[0].order_detail.pic,
            price: data[i].porder_detail[0].order_detail.price,
            num: data[i].porder_detail[0].order_detail.num,
            confirm_freight:
              data[i].porder_detail[0].order_detail.confirm_freight,
          },
        ];
      }
      //console.log("配送单", data);
    },
    // 数据处理
    dataProcessing(data, type) {
      data.forEach((dataItem) => {
        // 添加是否显示子数据的参数
        dataItem.showChildData = false;
        dataItem.totalPrice = 0;
        dataItem.totalNum = 0;
        dataItem.order_detail.forEach((goodsItem) => {
          // 添加多选框参数
          goodsItem.checked = false;
          //商品规格数据转化
          goodsItem.detail = JSON.parse(goodsItem.detail);
          // if (
          //   this.form.status === "国际运费待支付配送单" &&
          //   goodsItem.porder_detail.length > 0
          // ) {
          //   dataItem.porder_sn = goodsItem.porder_detail[0].porder_sn;
          // }
          // 商品小计计算
          goodsItem.totalPrice = this.$fun.roundNumber(
            goodsItem.confirm_price * goodsItem.confirm_num,
            2
          );
          dataItem.totalPrice += goodsItem.totalPrice;
          dataItem.totalNum += goodsItem.confirm_num;
        });
      });
    },
    // 商品单选
    goodsChioce() {
      this.allChioce(this.tableData);
    },
    // 商品全选
    allChecked() {
      this.tableData.forEach((dataItem) => {
        dataItem.order_detail.forEach((order_detailItem) => {
          order_detailItem.checked = this.checked;
        });
      });
      this.allChioce(this.tableData);
    },
    // 判断所有商品是否被选中,如果被选中就将选中商品的id传入ids
    allChioce(data) {
      var flag = true;
      this.actIds = [];
      data.forEach((dataItem) => {
        dataItem.order_detail.forEach((order_detailItem) => {
          if (order_detailItem.checked == false) flag = false;
          if (
            order_detailItem.checked &&
            this.actIds.indexOf(order_detailItem.id) == -1
          )
            this.actIds.push(order_detailItem.id);
        });
      });
      this.checked = flag;
    },
    // 提出配送单
    toDeliveryDetails() {
      //console.log(this.actIds);
      if (this.actIds.length == 0)
        return this.$message(this.$fanyi("请至少选择一项"));
      this.$fun.routerToPage("/deliveryDetails?id=" + this.actIds.join(","));
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.form.pageSize = 1;
      this.getTableNum();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTableNum();
    },
    // 添加商品
    addGoods() {
      if (this.actIds.length == 0)
        return this.$message(this.$fanyi("请至少选择一项"));
      this.$api
        .storageSendDeliverPreview({ ids: this.actIds.join(",") })
        .then((res) => {
          if (res.code != 0) return false;
          this.$parent.$parent.addGoods(res.data.result);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#warehouse {
  @import "../../../../css/mixin";
  width: 100%;

  button {
    cursor: pointer;
  }

  > nav {
    height: 50px;
    display: flex;
    background-color: #f6f6f6;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 130px;
      padding: 0 7px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;
      color: #000000;
      line-height: 26px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }

      .label {
        display: inline-block;
        margin-right: 7px;
      }

      .numberCount {
        width: 25px;
        height: 25px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 25px;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        height: 40px;
        border-radius: 4px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;

        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 400px;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/ .el-date-editor {
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }
    }

    .dataBody {
      margin-bottom: 20px;

      .myTable {
        // 设置表格所有样式
        font-size: 14px;
        color: #2f2f2f;
        line-height: 19px;

        * {
          transition: 0.3s;
        }

        li {
          flex: 1;
        }

        .flex05 {
          flex: 0.5;
        }

        .flex2 {
          flex: 2;
          &.shangpShuxing {
            overflow: auto;
            word-break: normal;
          }
        }

        .flex15 {
          flex: 1.5;
        }

        ul {
          display: flex;
          height: 100px;
          background: #fafafa;
          border-top: 1px solid #f1f1f1;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #f1f1f1;

            &:nth-last-child(1) {
              border: 0;
            }

            img {
              cursor: pointer;
            }
          }
        }

        // 设置表头样式
        .tableHead {
          height: 56px;
          background: #f0f0f0;
          display: flex;
          align-items: center;
          text-align: center;
        }

        // 设置表格内容样式
        .tableBody {
          display: block;

          border: solid 1px #ededed;

          .tableBodyOpt {
            // 设置表格内容里所有的单元格样式
            > div {
              display: flex;
              align-items: center;

              > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .optTitle {
              height: 60px;
              display: flex;
            }

            .serialNumber {
              display: flex;
              height: 75px;
              border-top: solid 1px #ededed;

              .order {
                flex: 5.5;
                display: flex;

                .img {
                  width: 51px;
                  text-align: center;
                  margin-right: 10px;

                  img {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    transform: rotate(90deg);

                    &.openIcon {
                      transform: rotate(270deg);
                    }
                  }
                }

                .order_sn {
                  flex: 15;
                  line-height: 30px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .guoJiWuLiuFeiYong {
                    width: 152px;
                    .yunfeipri {
                      color: #fbbb21;
                    }
                  }
                  button {
                    color: #1e2997;
                    cursor: pointer;
                  }
                }
              }

              .item {
                flex: 1;
                text-align: center;
              }

              .payBtn {
                flex: 1.5;

                span {
                  display: block;
                  height: 30px;
                  padding: 0 10px;
                  background: #2129a1;
                  border-radius: 4px;
                  line-height: 30px;
                  text-align: center;
                  color: #fff;
                  cursor: pointer;
                }
              }
            }
          }
        }

        // 单个单元格样式
        // Nº

        // 照片
        .picture {
          min-width: 100px !important;
          max-width: 100px !important;
          width: 100px !important;
        }

        // 属性
        .detail {
          max-width: 200px !important;
          min-width: 200px !important;
          width: 200px !important;

          &.body {
            overflow-y: auto;
          }

          // height: 120px;
          .detailBox {
            margin: 0 auto;
            width: 170px;
            max-height: 110px;
            min-height: 20px;
            display: flex;

            flex-direction: column;
            align-items: flex-start;

            > p {
              text-align: left;
            }
          }
        }

        .status {
          width: 250px !important;

          .gooodsQurestStatus {
            color: #ffa018;
          }

          .goGoods {
            cursor: pointer;
          }
        }

        .num,
        .price,
        .total,
        .freight,
        .remark {
          width: 100px;
          min-width: 100px !important;
          max-width: 100px !important;
        }

        .remark img {
          cursor: pointer;
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
</style>
