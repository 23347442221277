var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"warehouse"}},[_c('nav',[_c('span',{class:_vm.form.status == '待收货订单' ? 'active' : '',on:{"click":function($event){_vm.form.status = '待收货订单';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getTableNum();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("待收货")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[1].num))])]),_c('span',{class:_vm.form.status == '检品中订单' ? 'active' : '',on:{"click":function($event){_vm.form.status = '检品中订单';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getTableNum();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("检品中")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[2].num))])]),_c('span',{class:_vm.form.status == '在RAKUMART仓库中订单' ? 'active' : '',on:{"click":function($event){_vm.form.status = '在RAKUMART仓库中订单';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getTableNum();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("在RAKUMART仓库中")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[3].num))])]),_c('span',{class:_vm.form.status == '国际运费待报价配送单' ? 'active' : '',on:{"click":function($event){_vm.form.status = '国际运费待报价配送单';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getTableNum();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("国际运费待报价")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[4].num))])]),_c('span',{class:_vm.form.status == '国际运费待支付配送单' ? 'active' : '',on:{"click":function($event){_vm.form.status = '国际运费待支付配送单';
        _vm.form.page = 1;
        _vm.$forceUpdate();
        _vm.getTableNum();}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$fanyi("国际运费待支付")))]),_c('span',{staticClass:"numberCount"},[_vm._v(_vm._s(_vm.tableTypeNum[5].num))])])]),_c('div',[_c('div',{staticClass:"searchBox"},[_c('div',{staticClass:"myInput"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.searchText),expression:"form.searchText"}],attrs:{"type":"text","placeholder":_vm.$fanyi('请输入关键词搜索')},domProps:{"value":(_vm.form.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getTableNum()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "searchText", $event.target.value)}}})]),_c('button',{staticClass:"searchBtn",on:{"click":function($event){return _vm.getTableNum()}}},[_vm._v(" "+_vm._s(_vm.$fanyi("搜索"))+" ")])]),_c('div',{staticClass:"dataBody"},[_c('div',{staticClass:"myTable"},[_c('ol',{staticClass:"tableHead"},[_c('li',{staticClass:"flex05"},[_vm._v("Nº")]),_c('li',[_vm._v(_vm._s(_vm.$fanyi("照片")))]),_c('li',{staticClass:"flex2"},[_vm._v(_vm._s(_vm.$fanyi("描述")))]),_c('li',[_vm._v(_vm._s(_vm.$fanyi("国内运费")))]),_c('li',[_vm._v(_vm._s(_vm.$fanyi("单价")))]),_c('li',[_vm._v(_vm._s(_vm.$fanyi("数量")))]),_c('li',[_vm._v(_vm._s(_vm.$fanyi("小计")))]),_c('li',[_vm._v(_vm._s(_vm.$fanyi("状态")))]),(_vm.showCaoZuo)?_c('li',{staticClass:"flex15"},[_vm._v(_vm._s(_vm.$fanyi("操作")))]):_vm._e()]),_c('div',{staticClass:"tableBody"},_vm._l((_vm.tableData),function(tableDataItem,tableIndex){return _c('div',{key:tableIndex,staticClass:"tableBodyOpt"},[_c('div',{staticClass:"serialNumber"},[_c('div',{staticClass:"order"},[_c('div',{staticClass:"img"},[_c('img',{class:{
                      openIcon: tableDataItem.showChildData == true,
                    },attrs:{"src":require("../../../../assets/user-img/jinrujaintouxiao.svg"),"alt":""},on:{"click":function($event){tableDataItem.showChildData = !tableDataItem.showChildData;
                      _vm.$forceUpdate();}}})]),_c('div',{staticClass:"order_sn"},[_c('div',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$fanyi("订单号"))+"：")]),_c('button',{on:{"click":function($event){return _vm.$fun.routerToPage(
                            '/OrderDetails?type=purchase&order_sn=' +
                              tableDataItem.order_sn,
                            true
                          )}}},[_vm._v(" "+_vm._s(tableDataItem.order_sn)+" ")])]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$fanyi("配送单号"))+"：")]),_c('button',{on:{"click":function($event){return _vm.$fun.routerToPage(
                            '/deliveryDetails?type=purchase&order_sn=' +
                              tableDataItem.porder_sn
                          )}}},[_vm._v(" "+_vm._s(tableDataItem.porder_sn)+" ")])])]),(_vm.form.status === '国际运费待支付配送单')?_c('div',{staticClass:"guoJiWuLiuFeiYong"},[_vm._v(" "+_vm._s(_vm.$fanyi("国际运费"))+": "),_c('br'),_c('span',{staticClass:"yunfeipri"},[_vm._v("€"+_vm._s(_vm.$fun.EURNumSegmentation( tableDataItem.porder_all_price )))])]):_vm._e()])]),_c('div',{staticClass:"item"},[_vm._v(" "+_vm._s(tableDataItem.porder_num)+" ")]),_c('div',{staticClass:"item"},[_vm._v(" "+_vm._s(tableDataItem.totalPrice)+" ")]),_c('div',{staticClass:"item"},[_vm._v(" "+_vm._s(_vm.$fanyi(tableDataItem.statusName))+" ")]),(_vm.showCaoZuo)?_c('div',{staticClass:"payBtn item"},[(
                    [
                      '西班牙派送配送单',
                      '国际运费待支付配送单',
                      '购买完成' ].indexOf(_vm.form.status) != -1
                  )?_c('span',{on:{"click":function($event){return _vm.$fun.routerToPage(
                      '/deliveryDetails?type=purchase&order_sn=' +
                        tableDataItem.porder_sn
                    )}}},[_vm._v(" "+_vm._s(_vm.$fanyi("支付")))]):_vm._e()]):_vm._e()]),_vm._l((tableDataItem.order_detail),function(goodsItem,goodsIndex){return _c('ul',{directives:[{name:"show",rawName:"v-show",value:(tableDataItem.showChildData),expression:"tableDataItem.showChildData"}],key:goodsIndex},[_c('li',{staticClass:"flex05"},[_vm._v(_vm._s(goodsIndex + 1))]),_c('li',[_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('img',{staticStyle:{"width":"300px","height":"300px","cursor":"pointer"},attrs:{"src":goodsItem.pic,"alt":""}}),_c('el-image',{staticStyle:{"width":"60px","height":"60px","cursor":"pointer"},attrs:{"slot":"reference","src":goodsItem.pic},on:{"click":function($event){return _vm.$fun.toCommodityDetails(goodsItem.goods_id)}},slot:"reference"},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1)],1),_c('li',{staticClass:"flex2 shangpShuxing"},[_c('div',_vm._l((goodsItem.detail),function(item,index){return _c('p',{key:index,attrs:{"title":item.key + ':' + item.value}},[_c('span',{staticClass:"detailKey"},[_vm._v(_vm._s(item.key))]),_vm._v(" ： "),_c('span',{staticClass:"detailValue"},[_vm._v(_vm._s(item.value))])])}),0)]),_c('li',[_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation(goodsItem.confirm_freight))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation(goodsItem.price))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation(goodsItem.num))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$fun.RMBNumSegmentation( _vm.$fun.ceil(goodsItem.num * goodsItem.price) ))+" ")]),_c('li',[_vm._v("- -")]),(_vm.showCaoZuo)?_c('li',{staticClass:"flex15"},[_vm._v("- -")]):_vm._e()])})],2)}),0)])]),_c('div',{staticClass:"allpag"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.form.page = 1;
          _vm.getTableNum();}}},[_vm._v(_vm._s(_vm.$fanyi("回到首页")))]),_c('el-pagination',{attrs:{"current-page":_vm.form.page,"page-size":10,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }